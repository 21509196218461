import React from "react"
import { Link } from "gatsby"
import s from "./header.module.css"
export default () => (
  <div>
    <center>
      <img
        src="/runyon-solutions_web_logo_white&orange.png"
        alt="Runyon Solutions"
      />
      <p />
      <div className={s.link}>
        <Link to="/">About</Link> | <Link to="/team/">The Team</Link> |{" "}
        <Link to="/contact/">Contact</Link>
      </div>
    </center>
    <hr />
  </div>
)
