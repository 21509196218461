import React from "react"
import s from "./footer.module.css"

export default () => (
  <div className={s.footer}>
    <hr />
    <a href="https://github.com/runyontr">
      <img src="/GitHub-Mark-Light-32px.png" width="32px" alt="github"></img>
    </a>{" "}
    {"  "}
    <a href="https://linkedin.com/in/tom-runyon">
      <img src="/LI-In-Bug.png" width="32px" alt="linkedin" />
    </a>
  </div>
)
