import React from "react"
import { css } from "@emotion/core"
import Header from "./header.js"
import Footer from "./footer.js"
import { rhythm } from "../utils/typography"
import { Helmet } from "react-helmet"
export default ({ children }) => (
  <div
    css={css`
      margin: 0 auto;
      max-width: 960px;
      padding: ${rhythm(2)};
      padding-top: ${rhythm(1.5)};
    `}
  >
    <Helmet>
      <meta charSet="utf-8" />
      <title>Runyon Solutions</title>
      <link rel="canonical" href="https://runyon.dev" />
    </Helmet>
    <Header />
    <div
      css={css`
        max-width: 960px;
      `}
    >
      {children}
    </div>
    <Footer />
  </div>
)
